// eslint-disable-next-line import/no-webpack-loader-syntax
import csv from 'raw-loader!../mocks/eurofxref.csv';
import Papa from 'papaparse';

const { data } = Papa.parse(csv, {
  header: true,
  dynamicTyping: true,
  delimiter: ', ',
  transform: (value) => value.trim(),
});

data[0].EUR = 1;

export const currencies = data[0];

export const currencyIds = Object.keys(data[0])
  .slice(1)
  .filter((item) => item)
  .sort((a, b) => a.localeCompare(b));

export const fx = (quantity, _from, _to) => {
  let multiplier = 1;
  let from = _from;
  let to = _to;
  if (from === 'GBp' || from === 'GBX') {
    multiplier = 0.01;
    from = 'GBP';
  }
  if (to === 'GBp' || to === 'GBX') {
    multiplier = 100;
    to = 'GBP';
  }
  if (from === 'EUR' && to === 'EUR') return quantity;
  if (from === 'EUR') return quantity * currencies[to] * multiplier;
  if (to === 'EUR') return quantity * (1 / currencies[from]) * multiplier;

  return quantity * ((1 / currencies[from]) / (1 / currencies[to])) * multiplier;
};
